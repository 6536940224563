@import './../theme-bootstrap';

// Color should be moved to global variables after pilot
$color-grey: #a3a3a3;

/* stylelint-disable-next-line selector-type-no-unknown */
amazon-delivery-message {
  min-height: 27.59px;
}

.bwp-separator {
  align-items: center;
  color: $color-grey;
  font-family: $base-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px 0 4px;
  text-align: center;
  text-transform: uppercase;
  &::after,
  &::before {
    border-bottom: 1px solid $color-grey;
    content: '';
    flex: 1;
  }
  &:not(:empty)::after {
    margin-left: 20px;
  }
  &:not(:empty)::before {
    margin-right: 20px;
  }
}

#bwp-cart-root {
  --bwp-cart-indicator-button-x-offset: 0;
  --bwp-cart-indicator-button-y-offset: -50px;
  @include breakpoint($medium-up) {
    --bwp-cart-indicator-button-x-offset: -250px;
    --bwp-cart-indicator-button-y-offset: 0;
  }
}

.footer-backtotop {
  @include breakpoint($small-down) {
    bottom: 134px;
    right: 23px;
  }
}

// Styles specific to A/B Test: EXPERIMENT-1315
amazon-delivery-message {
  display: none;
}

#bwpFrame {
  display: none;
}

.bwp-separator {
  display: none;
}

body.not-prod,
body.EXPERIMENT-1315 {
  amazon-delivery-message {
    &.bwp-mobile-only {
      display: block;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    &.bwp-pc-only {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  #bwpFrame {
    display: block;
  }
  .bwp-separator {
    display: flex;
  }
}
